export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url, no username
   */
  facebook?: string;
  /**
   * full url, no username
   */
  pinterest?: string;
  /**
   * full url, no username
   */
  twitter?: string;
  /**
   * full url, no username
   */
  instagram?: string;
  /**
   * full url, no username
   */
  flickr?: string;
  /**
   * full url, no username
   */
  youtube?: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;
  /**
  /**
   * Meta tag for Google Webmaster Tools
   */
  googleSiteVerification?: string;
  /**
  /**
   * Meta tag for Pinterest Site Verification
   */
  pinterestSiteVerification?: string;
  /**
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
}

const config: WebsiteConfig = {
  title: 'Bruhn Natur',
  description: 'Der Familien Blog über Natur und reisen mit Hund.',
  coverImage: 'img/20200215-Mummelsee.png',
  logo: 'img/20200321-Logo Bruhn-Natur.png',
  lang: 'de',
  siteUrl: 'https://www.bruhn-natur.de',
  facebook: 'https://www.facebook.com/Bruhn-Natur-109812303968484',
  pinterest: 'https://www.pinterest.com/bruhnnatur',
  instagram: 'https://www.instagram.com/bruhnnatur',
  flickr: 'https://www.flickr.com/photos/187414722@N05/',
  youtube: 'https://www.youtube.com/channel/UCiZvBtHQtK-qhFpymP7WAzw',
  showSubscribe: false,
  pinterestSiteVerification: '59b28c2e857f1f1228ad71e00aa678cf',
};

export default config;
