import { graphql, Link, StaticQuery } from 'gatsby';
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import * as React from 'react';
import { css } from '@emotion/react';

import config from '../../website-config';

const SiteNavLogoStyles = css`
  flex-shrink: 0;
  display: block;
  margin-right: 24px;
  padding: 11px 0;
  color: #fff;
  font-size: 1.7rem;
  line-height: 1em;
  font-weight: bold;
  letter-spacing: -0.5px;

  :hover {
    text-decoration: none;
  }

  img {
    display: block;
    width: auto;
    height: 21px;
  }
`;

interface SiteNavLogoProps {
  logo?: {
    childImageSharp: any;
  };
}

const SiteNavLogo = () => (
  <StaticQuery
    query={graphql`query HeadingQuery {
  logo: file(relativePath: {eq: "img/20200321-Logo Bruhn-Natur.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FIXED)
    }
  }
}
`}
    // tslint:disable-next-line:react-this-binding-issue
    render={(data: SiteNavLogoProps) => (
      <Link className="site-nav-logo" css={SiteNavLogoStyles} to="/">
        {data.logo ? (
          <GatsbyImage image={getImage(data.logo.childImageSharp.gatsbyImageData)} alt={config.title} />
        ) : (
          config.title
        )}
      </Link>
    )}
  />
);

export default SiteNavLogo;
